import type { LinkProps as RemixLinkProps } from "@remix-run/react";
import { Link as RemixLink } from "@remix-run/react";
import type { AppUrl } from "@tamarack/shared/AppUrl";

export type BaseLinkProps<T> = Omit<T, "to"> & {
  to: AppUrl;
};

export type LinkProps = BaseLinkProps<RemixLinkProps>;

/**
 * @deprecated Use the new link component that accepts route access isntead of the `to` prop
 */
export default function Link_DEPRECATED({ to, ...linkProps }: LinkProps) {
  return <RemixLink to={to.toString()} prefetch="intent" {...linkProps} />;
}
